import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { ToggleSwitch } from "clutch/src/ToggleSwitch/ToggleSwitch.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import * as lolActions from "@/feature-event-replay/lol-actions.mjs";
import Buttons from "@/feature-event-replay/RecordingButtons.jsx";
import {
  Form,
  Input,
  Modal,
  Select,
  Steps,
  TextContainer,
  Toolbar,
} from "@/feature-event-replay/RecordingToolbar.style.jsx";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import {
  addBotsToGameLobby,
  createGameLobby,
} from "@/game-lol/utils/lol-client-api.mjs";
import { useGameSymbol } from "@/util/game-route.mjs";
import globals from "@/util/global-whitelist.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";
import { useTransientRoute } from "@/util/router-hooks.mjs";
import SymbolMap from "@/util/symbol-map.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const actions = new SymbolMap({
  [GAME_SYMBOL_LOL]: lolActions,
});

function CreateCustomGame() {
  const gameConfig = {
    customGameLobby: {
      configuration: {
        gameMode: "PRACTICETOOL",
        mapId: 11,
        mutators: {
          id: 1,
        },
        spectatorPolicy: "NotAllowed",
        teamSize: 5,
      },
      lobbyName: ":disguised_clown:",
      lobbyPassword: "deli meats",
    },
    isCustom: true,
  };

  async function createCustomLobbyAndAddBots() {
    await createGameLobby(gameConfig);
    await Promise.all([
      addBotsToGameLobby(85, "TOP", "100"),
      addBotsToGameLobby(11, "JUNGLE", "100"),
      addBotsToGameLobby(13, "MIDDLE", "100"),
      addBotsToGameLobby(53, "UTILITY", "100"),
    ]);
    await Promise.all([
      addBotsToGameLobby(36, "TOP", "200"),
      addBotsToGameLobby(33, "JUNGLE", "200"),
      addBotsToGameLobby(69, "MIDDLE", "200"),
      addBotsToGameLobby(81, "BOTTOM", "200"),
      addBotsToGameLobby(16, "UTILITY", "200"),
    ]);
  }

  return (
    <Buttons.Play
      data-tooltip={"Create Custom Game"}
      onClick={createCustomLobbyAndAddBots}
    />
  );
}

function SaveRecordingMenu() {
  const currentGame = useGameSymbol();
  const [name, setName] = useState("");
  const { saveCurrentRecording, clearCurrentRecording } = actions[currentGame];

  const handleNameInput = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const handleSaveRecording = useCallback(
    async (e) => {
      e.preventDefault();
      await saveCurrentRecording(name, currentGame);
    },
    [currentGame, name, saveCurrentRecording],
  );

  return (
    <Form onSubmit={handleSaveRecording}>
      <Input
        placeholder="enter recording name"
        value={name}
        onChange={handleNameInput}
      />
      <Buttons.Save disabled={!name} type="submit" />
      <Buttons.Reset
        role="button"
        type="button"
        onClick={clearCurrentRecording}
      />
    </Form>
  );
}

function DownloadModal({ onClose }) {
  const state = useSnapshot(readState);
  const currentGame = useGameSymbol();
  const { selectedRecording } = state.eventReplay;
  const { uploadRecording } = actions[currentGame];
  const textRef = useRef<HTMLInputElement>();

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (!textRef.current) return;

      const rawText = textRef.current.value;
      const failed = uploadRecording(rawText);

      if (!failed) {
        onClose();
      }
    },
    [onClose, uploadRecording],
  );

  useLayoutEffect(() => {
    const el = textRef.current;
    if (selectedRecording && el) {
      el.innerText = JSON.stringify(
        {
          ...selectedRecording,
          game: GAME_SHORT_NAMES[currentGame],
        },
        null,
        2,
      );

      const range = globals.document.createRange();
      range.selectNodeContents(el);
      // eslint-disable-next-line no-restricted-properties
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }, [currentGame, selectedRecording]);

  return (
    <Modal>
      <Form onSubmit={handleSubmit}>
        <TextContainer>
          <pre ref={textRef} />
        </TextContainer>
        <Buttons.Close role="button" type="button" onClick={onClose} />
        {!selectedRecording && <Buttons.Save type="submit" />}
      </Form>
    </Modal>
  );
}

function Menu() {
  const state = useSnapshot(readState);
  const currentGame = useGameSymbol();
  const initializedRef = useRef(false);
  const [expanded, setExpanded] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const {
    recordingIds,
    mode,
    currentRecording = [],
    currentStep,
    totalSteps,
    captureGql,
  } = state.eventReplay;
  const {
    MODE_IDLE,
    MODE_RECORDING,
    MODE_PAUSED,
    MODE_PLAYING,
    setSelectedRecordng,
    toggleRecording,
    getLatestRecording,
    step,
    rewind,
    play,
    pause,
    clear,
    disableCaptureGqlRequests,
    enableCaptureGqlRequests,
  } = actions[currentGame];

  const handleRecordingSelect = useCallback(
    async (e) => {
      const recordingId = e.target.value;
      await setSelectedRecordng(recordingId);
    },
    [setSelectedRecordng],
  );

  const recordingOptions = (recordingIds || [])
    .filter((r) => r.split(":")[0] === GAME_SHORT_NAMES[currentGame])
    .map((r, i) => (
      <option key={i} value={r}>
        {r.split(":")[1]}
      </option>
    ));

  useLayoutEffect(() => {
    if (initializedRef.current) return;
    getLatestRecording();
    initializedRef.current = true;
  }, [getLatestRecording, setSelectedRecordng]);

  if (!expanded) {
    return (
      <Toolbar>
        <Buttons.Gear onClick={() => setExpanded(true)} />
      </Toolbar>
    );
  }

  return (
    <Toolbar className="open">
      {mode === MODE_IDLE ? (
        currentRecording.length > 0 ? (
          <SaveRecordingMenu />
        ) : (
          <>
            <CreateCustomGame />
            {(captureGql || IS_APP) && (
              <Buttons.Record
                data-tooltip="Record LCU events"
                onClick={toggleRecording}
              />
            )}
            <Select onChange={handleRecordingSelect} defaultValue="">
              <option value="">{`Select Recording`}</option>
              {recordingOptions}
            </Select>
            <Buttons.Download onClick={() => setShowDownloadModal(true)} />
            <ToggleSwitch
              value={captureGql}
              onChange={() =>
                captureGql
                  ? disableCaptureGqlRequests()
                  : enableCaptureGqlRequests()
              }
              labelText={"Capture GQL"}
            />
            <Buttons.Close onClick={() => setExpanded(false)} />
          </>
        )
      ) : mode === MODE_RECORDING ? (
        <>
          <Buttons.Stop onClick={toggleRecording} />
          <Steps>
            {currentRecording.length} {`events`}
          </Steps>
        </>
      ) : (
        <>
          {mode === MODE_PLAYING ? (
            <Buttons.Pause onClick={pause} />
          ) : mode === MODE_PAUSED ? (
            <Buttons.Play onClick={play} />
          ) : null}
          <Buttons.Prev onClick={() => step(-1)} />
          <Buttons.Next onClick={() => step()} />
          <Buttons.Restart onClick={rewind} />
          <Buttons.Clear onClick={clear} />
          <Buttons.Upload onClick={() => setShowDownloadModal(true)} />
          <Steps>
            {currentStep}
            <i />
            {totalSteps}
          </Steps>
        </>
      )}
      {showDownloadModal && (
        <DownloadModal onClose={() => setShowDownloadModal(false)} />
      )}
    </Toolbar>
  );
}

function RecordingToolbar() {
  const currentGame = useGameSymbol();
  const route = useTransientRoute();
  const isActionsPresent =
    Object.getOwnPropertySymbols(actions).includes(currentGame);
  const isOverlay = isRouteOverlay(route.currentPath);

  if (!isActionsPresent || !readState.eventReplay || isOverlay) return null;

  return <Menu />;
}

export default RecordingToolbar;
